angular.module('fingerink')
    .service('swalService', function ($translate) {

        this.basicSwal = (a, b, c) => swal($translate.instant(a), $translate.instant(b), c);

        this.requestSwal = (title, text, type, p, cancel) => {
            return new Promise((resolve, reject) => {

                swal({
                    title: $translate.instant(title),
                    text: $translate.instant(text),
                    type: type,
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: $translate.instant('Continuar'),
                    preConfirm: () =>  p().then(response => resolve(response), error => reject(error))                    
                }).then((result) => {
                    if (!result.value) {
                        if (cancel) {
                            cancel();
                        }
                    }
                });
            });
        };
    });
